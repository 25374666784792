@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

/* Layout */

.ant-layout-content {
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-style: regular;
  color: #041836;
  margin-top: 40px;
}

.ant-layout {
  overflow: auto;
}

.ant-layout-footer {
  text-align: center;
}

.ant-layout-footer .ant-typography {
  display: block;
}

.ant-layout-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px !important;
  width: 100%;
}

.ant-layout-header .left {
  display: flex;
  width: 100%;
  justify-content: left;
}

.ant-layout-header .right {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  font-size: 15px;
  font-weight: 600;
  width: auto;
  justify-content: right;
}

.ant-layout-header .right button {
  width: 120px;
  height: 36px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  grid-gap: 10px;
  gap: 10px;
}

.ant-layout-header .empty {
  width: 0%;
}

.ant-layout-header .right button > span {
  font-size: 15px;
}

/* Modals */

.ant-modal-content {
  overflow: auto;
  border-radius: 16px !important;
  box-shadow: 0 8px 11px -4px rgb(0 0 0 / 12%), 0 11px 21px 0 rgb(0 0 0 / 8%),
    0 14px 33px 13px rgb(0 0 0 / 5%);
}

.ant-modal-header {
  height: 64px;
  padding-top: 20px !important;
}

.ant-modal-title {
  font-weight: 600 !important;
  size: 18px;
  line-height: 24px !important;
}

.ant-modal-close-x {
  height: 64px !important;
  line-height: 64px !important;
  width: 64px !important;
}

.ant-modal-close-icon svg {
  width: 16px;
  height: 16px;
  font-weight: 900;
}

.ant-modal-body {
  padding-bottom: 0px !important;
}

.ant-modal-confirm-btns .ant-btn {
  border-radius: 16px !important;
}

.ant-modal-confirm-body-wrapper {
  padding-bottom: 20px;
}

/* Forms */
.ant-form-item-label label {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  padding: 0px !important;
}

.ant-upload {
  width: 100%;
}

.ant-form-item-control-input-content .ant-upload button {
  margin: 0px;
}

.ant-form-item-control-input-content input,
.ant-form-item-control-input-content .ant-input-password,
.ant-form-item-control-input-content .ant-select,
.ant-form-item-control-input-content .ant-picker,
.ant-form-item-control-input-content .ant-picker-range,
.ant-form-item-control-input-content .ant-input-number-input,
.ant-form-item-control-input-content .ant-input-number-in-form-item,
.ant-form-item-control-input-content .ant-upload button {
  border-radius: 8px !important;
  padding: 16px;
}

.ant-input-number {
  padding: 0px !important;
}

.ant-input-number-input {
  height: 59.14px !important;
}

.ant-form-item-control-input-content .ant-input-number,
.ant-form-item-control-input-content .ant-picker-range,
.ant-form-item-control-input-content .ant-picker {
  width: 100%;
}

.ant-form-item-control-input-content .ant-select-selector {
  border: 0px !important;
}

.ant-form-item-control-input-content button {
  grid-gap: 10px;
  gap: 10px;
  height: 56px;
  font-weight: 600;
  size: 16px;
  line-height: 24px;
}

.ant-form-item-control-input-content .ant-switch {
  height: 22px;
}

.ant-form-item-control-input-content .ant-checkbox + span {
  font-weight: 600;
  size: 16px;
  line-height: 24px;
}

/* Containers */

.layout-hotel .container,
.layout-user .container {
  margin-top: 40px;
}
.container {
  display: block;
  padding: 0px 16px;
}

.container h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}

.container h2 {
  font-size: 150%;
  text-align: center;
}

.container h3 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
}

.container h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 15px;
}

/* Panels */

.panel {
  padding: 20px;
  padding-bottom: 0px;
  margin-top: 15px;
  border-radius: 16px;
}

.container-table > .panel {
  padding: 0px;
  margin-top: 15px;
  border-radius: 0px;
}

/* Tables */

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan]):before {
  width: 3px !important;
}

/* Home */

.home-container {
  display: block;
}

.home-container h1 {
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
}

.home-container h2 {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

@media only screen and (max-width: 900px) {
  .home-container h1 {
    padding-top: 40px;
    text-align: center;
    font-size: 250%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .home-container h2 {
    font-size: 170%;
    text-align: center;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .home-container h1 {
    font-size: 180%;
    line-height: 1.2 !important;
  }

  .home-container h2 {
    font-size: 140%;
  }
}

/* Fields */

.reservation-field-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.4px;
}

.reservation-field-value {
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 16px;
}

.reservation-buttons button {
  margin-bottom: 20px;
  height: 56px !important;
  font-weight: 600;
  size: 16px;
  line-height: 24px;
}

.ant-collapse-header {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  width: 130px !important;
  margin-left: auto;
  margin-right: auto;
}

/* Dividers */

.ant-divider-horizontal.ant-divider-with-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px !important;
  line-height: 16px;
}

.ant-divider-horizontal {
  margin-top: 30px !important;
  border-top: 0px !important;
}

.ant-divider-horizontal.first {
  margin-top: 20px !important;
}

/* Menus */

.ant-menu {
  font-family: "Poppins";
  display: flex !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  width: 100%;
}

.ant-menu-horizontal {
  border-bottom: 0px !important;
}

.app-logo {
  height: 32px;
  margin-left: 5px;
  margin-right: 100px;
}

.app-logo-menu {
  padding-top: 5px;
  margin-top: 10px;
}

.app-logo-small {
  display: none;
}

/* Table button */
.reservations-buttons {
  display: flex;
  justify-content: flex-end;
}

button.passcode,
button.delete {
  margin-left: 10px;
}

button.passcode svg,
button.delete svg {
  margin-top: 5px;
}

/* Reserve */
.reserve-container .panel,
.reservation-container .panel {
  margin-bottom: 32px;
}

/* Misc */

.identicon {
  border-radius: 50px;
}

#root {
  white-space: pre-line;
}

/* Check-in state */

.check-in-state {
  margin-top: 20px;
}

.check-in-state h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 24px;
}

.check-in-state button {
  width: 120px;
  height: 56px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
}

/* Website selector */
.website-selector-container h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 40px;
  margin-top: 16px;
}

.website-selector-container .panel {
  padding: 32px;
  margin-top: 0px;
  max-width: 480px;
}

.website-selector-container .panel h1 {
  margin: 0px;
  padding-top: 0px;
}

.website-selector-container h2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 32px;
}

.website-selector-container button {
  grid-gap: 10px;
  gap: 10px;
  height: 56px;
  border-radius: 1000px;
  font-weight: 600;
  size: 16px;
  line-height: 24px;
  padding: 8px 20px;
  margin: 8px;
}

.website-selector-container .last {
  margin-bottom: 0px;
}

.app-logo-without-menu {
  margin-left: 0px;
  margin-right: 0px;
}

/* Website selector control */
.website-selector-button {
  position: fixed !important;
  bottom: 24px;
  right: 24px;
}

/* Corporate credential */
.corporate-credential-container h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 40px;
}

.corporate-credential-container .panel {
  padding: 32px;
  max-width: 480px;
  margin-top: 40px;
  margin-bottom: 32px;
}

.corporate-credential-container .panel h1 {
  margin: 0px;
  padding-top: 0px;
}

.corporate-credential-container h2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 24px;
}

.corporate-credential-container button {
  grid-gap: 10px;
  gap: 10px;
  height: 56px;
  border-radius: 1000px;
  font-weight: 600;
  size: 16px;
  line-height: 24px;
  padding: 8px 20px;
  margin: 8px;
}

.corporate-credential-container .last {
  margin-bottom: 0px;
}

.corporate-credential-container .ant-picker-suffix {
  height: 20px;
  width: 20px;
}

.corporate-credential-container .ant-result {
  padding: 0px;
  margin: 0px;
}

.corporate-credential-container .ant-result-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.corporate-credential-container .ant-result-subtitle {
  padding-top: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.corporate-credential-container .ant-result-subtitle p {
  margin: 4px !important;
}

/* Neoke logo */
.neoke-logo {
  height: 32px;
  margin: 0 auto;
}

.self-service-panel {
  max-width: 480px !important;
  padding-bottom: 12px;
}

.corporate-credential-result .ant-result-icon {
  margin-top: -24px;
}

.ant-result-extra button {
  height: 40px;
}

/* Hotel */
.hotel-container .panel,
.hotels-container .panel {
  padding: 32px;
  font-family: "Poppins";
  font-style: normal;
  color: #131314;
  max-width: 600px;
  margin-bottom: 32px;
  padding-bottom: 6px;
}

.hotel-data-image {
  float: left;
}

.hotel-data-description {
  display: flex;
  height: 120px;
  margin-left: 176px;
  flex-direction: column;
}

.hotels-container .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  padding-bottom: 24px;
}

.hotels-container .subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  padding-bottom: 24px;
}

.hotel-data-buttons {
  float: right;
  margin-top: -36px;
}

.create-hotel-button {
  margin-bottom: 24px;
  grid-gap: 10px !important;
  gap: 10px !important;
  height: 56px !important;
  font-weight: 600 !important;
  size: 16px !important;
  line-height: 24px I !important;
}

.see-hotel-details-button {
  grid-gap: 10px !important;
  gap: 10px !important;
  height: 36px !important;
  font-weight: 600 !important;
  size: 14px !important;
  line-height: 20px I !important;
  padding: 0px 20px !important;
}

.hotel img {
  border: 1px solid #000000;
  width: 160px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 16px;
}

.hotel .hotel-name,
.hotel .hotel-slogan {
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
}

.hotel .hotel-address {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(75, 100, 112, 0.7);
}

.hotel .hotel-speedy-check-in {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.hotel-speedy-check-in .content {
  padding: 2px 12px;
  grid-gap: 10px;
  gap: 10px;
  font-size: 12px;
  line-height: 16px;
  background: #d1effc;
  border-radius: 80px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.hotel .hotel-price {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #131314;
}

.hotel .hotel-price span {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(75, 100, 112, 0.7);
  margin-bottom: 15px;
  padding-left: 6px;
}

.hotel-extended-info {
  padding-top: 24px;
}

.hotel .hotel-description {
  line-height: 26px;
  font-size: 14px;
  padding-top: 4px;
}

.create-hotel-container .panel {
  padding: 32px;
  margin-bottom: 32px;
}

@media only screen and (max-width: 600px) {
  .hotel img {
    width: 104px;
  }

  .hotel-data-description {
    margin-left: 120px;
  }
}

/* Search */
.search-container .panel {
  padding: 32px;
  font-family: "Poppins";
  font-style: normal;
  color: #131314;
  max-width: 600px;
  margin-bottom: 32px;
  padding-bottom: 6px;
}

.search-container h3 {
  padding-bottom: 22px;
}

/* Reserve */
.reserve-container .panel {
  padding: 32px;
  font-family: "Poppins";
  font-style: normal;
  color: #131314;
  max-width: 600px;
  margin-bottom: 32px;
  padding-bottom: 6px;
}

.reserve-container h3 {
  padding-bottom: 22px;
}

.reserve-container .speedy-check-in-switch {
  float: left;
}

.reserve-container .speedy-check-in-info {
  display: block;
  margin-left: 62px;
}

.speedy-check-in-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #200b47;
}

.speedy-check-in-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #81929b;
  margin-bottom: 24px;
}

